/*
 * Copyright 2018 - 2021 Swiss Federal Institute of Technology Lausanne (EPFL)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0.
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * This open source software code was developed in part or in whole in the
 * Human Brain Project, funded from the European Union's Horizon 2020
 * Framework Programme for Research and Innovation under
 * Specific Grant Agreements No. 720270, No. 785907, and No. 945539
 * (Human Brain Project SGA1, SGA2 and SGA3).
 *
 */

import React from 'react';
import Form from 'react-bootstrap/Form';
import { createUseStyles } from 'react-jss';
import Markdown from '../../Components/Markdown';
import InputText from '../InputText/InputText';
import Label from '../Label';
import type InputTextStore from '../Stores/InputTextStore';
import type { Field } from '../index';

const useStyles = createUseStyles({
  label: {},
  editMode: {
    '& textarea': {
      minHeight: '200px'
    }
  },
  readMode: {
    '& $label:after': {
      content: '\':\\00a0\''
    }
  }
});

interface TextAreaProps extends Field {
  fieldStore: InputTextStore;
}

const TextArea = (props: TextAreaProps) => {
  const classes = useStyles();
  const { readMode, fieldStore, className, showIfNoValue } = props;
  const { label, value } = fieldStore;
  if (readMode) {

    if(!value && !showIfNoValue) {
      return null;
    }

    if (fieldStore.markdown) {
      return (
        <Form.Group className={`${classes.readMode} ${className}`} >
          {label && (
            <Label className={classes.label} label={label} />
          )}
          <Markdown value={value}/>
        </Form.Group>
      );
    }
    return (
      <Form.Group className={`${classes.readMode} ${className}`} >
        {label && (
          <Label className={classes.label} label={label} />
        )}
        <p>{fieldStore.value}</p>
      </Form.Group>
    );
  }
  return (
    <InputText {...props} className={`${classes.editMode} ${className}`} as="textarea" />
  );
};

export default TextArea;